// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'common/components/Button';
import TitleBlock from 'marketing/components/TitleBlock';
import ViewingUnit from 'marketing/components/ViewingUnit';

// Styles
import {
  details,
  prizeImage,
  sidebar,
  sidebarButton,
  logo,
  main,
  sub,
  contributedBy
} from './styles.module.css';

const GrandPrizeDetails = ({ data, cashPrize }) => {
  const {
    heading,
    subheading,
    description,
    supportText,
    imageAltText,
    showhomeBlockTitle,
    showhomeViewingHours,
    showhomeAddressText,
    mapImage,
    contribution,
    supplierLogo,
    primaryPrizeButtons,
    primaryPrizeImages
  } = data;

  // TEMPORARY HOTFIX FOR PRODUCTION
  const image = primaryPrizeImages && primaryPrizeImages.length > 0 && primaryPrizeImages[0].image;

  return (
    <>
      {image && (
        <div className={prizeImage} style={image && { backgroundImage: `url(${image})` }} />
      )}
      <div className={details}>
        <div className="l-col-8">
          {heading && <h2 className={`${main} headingFont theme-color-primary`}>{heading}</h2>}

          {subheading && <p className={`${sub} h4 theme-color-primary`}>{subheading}</p>}

          {description && (
            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {supportText && (
            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: supportText }} />
          )}
        </div>

        <div className={`${sidebar} l-col-3 l-col-offset-1`}>
          {primaryPrizeButtons &&
            primaryPrizeButtons
              .filter(button => button.url || button.pdf)
              .map(button => (
                <Button
                  to={button.pdf ? button.pdf : button.url}
                  text={button.buttonText}
                  key={button.buttonText}
                  modifiers="secondary sm-small"
                  extraClasses={sidebarButton}
                  external
                />
              ))}

          {supplierLogo && (
            <>
              {contribution && <p className={contributedBy}>{contribution}</p>}
              <img className={logo} src={supplierLogo} alt={imageAltText} />
            </>
          )}
        </div>

        {(showhomeBlockTitle || showhomeViewingHours || showhomeAddressText) && (
          <div className="l-col-8">
            <ViewingUnit
              showhomeBlockTitle={showhomeBlockTitle}
              showhomeViewingHours={showhomeViewingHours}
              showhomeAddressText={showhomeAddressText}
              mapImage={mapImage}
            />
          </div>
        )}

        {cashPrize && (
          <div className="l-col-12">
            <TitleBlock
              mainHeading={cashPrize.cashPrizeHeading}
              subHeading={cashPrize.cashPrizeSubheading}
            />
          </div>
        )}
      </div>
    </>
  );
};

GrandPrizeDetails.propTypes = {
  data: PropTypes.shape({
    contribution: PropTypes.string,
    description: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.string,
    imageAltText: PropTypes.string,
    mapImage: PropTypes.string,
    primaryPrizeButtons: PropTypes.arrayOf(
      PropTypes.shape({
        buttonText: PropTypes.string,
        pdf: PropTypes.string
      })
    ),
    showhomeAddressText: PropTypes.string,
    showhomeBlockTitle: PropTypes.string,
    showhomeViewingHours: PropTypes.string,
    subheading: PropTypes.string,
    supplierLogo: PropTypes.string,
    supportText: PropTypes.string
  }).isRequired,
  cashPrize: PropTypes.shape({
    cashPrizeHeading: PropTypes.string,
    cashPrizeSubheading: PropTypes.string
  }).isRequired
};

export default GrandPrizeDetails;
