// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styling
import { viewingUnit,
  mapContainer,
  mapImg,
  content,
  heading,
  copyContainer,
  copyTitle } from './styles.module.css';

const ViewingUnit = ({
  showhomeBlockTitle,
  showhomeViewingHours,
  showhomeAddressText,
  mapImage,
}) => (
  <>
    <div className={viewingUnit}>
      {mapImage && (
        <div className={mapContainer}>
          <img className={mapImg} src={mapImage} alt="Map of location" />
        </div>
      )}

      <div className={content}>
        {showhomeBlockTitle && (
          <h5 className={`${heading} theme-color-primary`}>
            {showhomeBlockTitle}
          </h5>
        )}

        {showhomeViewingHours && (
          <>
            <p className={copyTitle}>Viewing Hours</p>
            <div
              className={copyContainer}
              dangerouslySetInnerHTML={{ __html: showhomeViewingHours }}
            />
          </>
        )}

        {showhomeAddressText && (
          <>
            <p className={copyTitle}>Address</p>
            <div
              className={copyContainer}
              dangerouslySetInnerHTML={{ __html: showhomeAddressText }}
            />
          </>
        )}
      </div>
    </div>
  </>
);

ViewingUnit.propTypes = {
  showhomeBlockTitle: PropTypes.string.isRequired,
  showhomeViewingHours: PropTypes.string.isRequired,
  showhomeAddressText: PropTypes.string.isRequired,
  mapImage: PropTypes.string.isRequired,
};

export default ViewingUnit;
