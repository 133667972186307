// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import Hero from 'marketing/components/Hero';
import TitleBlock from 'marketing/components/TitleBlock';
import Notification from 'marketing/components/Notification';
import GrandPrizeDetails from 'marketing/components/GrandPrizeDetails';

// Styles
import { layoutContainer } from './styles.module.css';

export default (data) => {
  const {
    bannerMessage,
    bannerTicketsLinkText,
    displayBanner,
    heroSubheading,
    heroHeading,
    heroImage,
    titleHeading,
    titleSubheading,
    primaryPrizeComponents,
    cashPrizeHeading,
    cashPrizeSubheading,
  } = data.pageContext.data;

  const cashPrizeComponents = {
    cashPrizeHeading,
    cashPrizeSubheading,
  };

  return (
    <LayoutContainer
      includeOrderTicketsCta
      includeWinnersLink={data.pageContext.winnersLink}
    >

      <Hero subHeading={heroSubheading} mainHeading={heroHeading} backgroundUrl={heroImage} />

      <div className="theme-background-primary">
        <div className={layoutContainer}>

          <TitleBlock
            mainHeading={titleHeading}
            subHeading={titleSubheading}
            onThemeBackground
          />
          <div className="l-margin-top--40">

            {displayBanner
              && <Notification message={bannerMessage} linkText={bannerTicketsLinkText} />
            }

            {primaryPrizeComponents && primaryPrizeComponents.map((prizeComponent, i, arr) => (
              // only pass the cashPrize data to the last primaryPrizeComponent
              <GrandPrizeDetails
                data={prizeComponent}
                key={parseInt(i.toString(), 10)} // TODO decide on a better way of this.
                cashPrize={arr.length - 1 === i && cashPrizeComponents}
              />
            ))}
          </div>

        </div>
      </div>

    </LayoutContainer>
  );
};
